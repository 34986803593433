export default {
  methods: {
    closeNotification() {
      this.$store.commit('layouts/UPDATE_NOTIFICATION', {
        display: false,
        type: '',
        message: '',
        item: '',
        callback: {
          text: '',
          method: '',
        },
      })
    },
    setSuccessNotification(message, item, callbackMethod) {
      this.$store.commit('layouts/UPDATE_NOTIFICATION', {
        display: true,
        type: 'success',
        message,
        item,
        callback: {
          text: 'OK',
          method: callbackMethod,
        },
      })
    },
    setFailedNotification(message, item) {
      this.$store.commit('layouts/UPDATE_NOTIFICATION', {
        display: true,
        type: 'failed',
        message,
        item,
        callback: {
          text: 'OK',
          method: 'closeNotification',
        },
      })
    },
    setAlertNotification(message, item, callbackText) {
      this.$store.commit('layouts/UPDATE_NOTIFICATION', {
        display: true,
        type: 'alert',
        message,
        item,
        callback: {
          text: callbackText || 'OK',
          method: 'closeNotification',
        },
      })
    },
    setWarningNotification(message, item, callbackText, callbackMethod) {
      this.$store.commit('layouts/UPDATE_NOTIFICATION', {
        display: true,
        type: 'warning',
        message,
        item,
        callback: {
          text: callbackText,
          method: callbackMethod,
        },
      })
    },
    displayPixelToast(
      title,
      variant = 'success',
      position = 'top',
      duration = 3000
    ) {
      // variant: success, info, error, warning // default variant success, default position top, default duration 3000
      this.$toast({
        variant,
        title,
        position,
        duration,
      })
    },
  },
}
