/* eslint-disable no-sequences */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable no-unused-expressions */
/* NOTE: This config is based on Zoho Survey Account config. */
/* NPS (Qontak Chatpanel Production) - Q2 2022 || https://survey.zoho.com/survey/newui#/portal/711535804/department/nrBUFe/survey/617686000002904199/launch */
/* NPS (Qontak Chatpanel Staging) - Q2 2022 || https://survey.zoho.com/survey/newui#/portal/711535804/department/nrBUFe/survey/617686000002902939/launch */

export default {
  methods: {
    /**
     *
     * const zohoCode = 'fbCsU3' 
     * const customVar = [
        { var_type: 2, c_var_id: '617686000003084051', var_name: 'eventType' },
        { var_type: 2, c_var_id: '617686000003084027', var_name: 'organizationId', },
        { var_type: 2, c_var_id: '617686000002904331', var_name: 'userRole' },
        { var_type: 2, c_var_id: '617686000003084045', var_name: 'surveyId' },
        { var_type: 2, c_var_id: '617686000003084033', var_name: 'segment' },
        { var_type: 2, c_var_id: '617686000003084039', var_name: 'industry' },
        { var_type: 2, c_var_id: '617686000003185283', var_name: 'userId' },
        { var_type: 2, c_var_id: '617686000003185295', var_name: 'companyId' },
      ] 
     * window.userRole = 'testing-role'
     * window.organizationId = '123456-78910'
     * window.segment = 'Segment'
     * window.industry = '-'
     * window.surveyId = '0987-654-321'
     * window.eventType = 'resolved_room'
     * window.userId = '111-222-333'
     * window.companyId = '468815'
     * @param { String } code | ex: zohoCode 
     * @param { Array } customVariables | customVar
     */
    initZoho(code, customVariables) {
      ;(function (w, d, s, u, f, m, n, o) {
        o = 'https://survey.zohopublic.com'
        w[f] =
          w[f] ||
          function () {
            ;(w[f].p = w[f].p || []).push(arguments)
          }
        ;(m = d.createElement(s)), (n = d.getElementsByTagName(s)[0])
        m.async = 1
        m.src = o + u
        n.parentNode.insertBefore(m, n)
        zs_intercept(o, `${code}`, {
          minHeight: 542,
          heightType: 1,
          maxHeight: 542,
          displayPeriod: 4,
          position: 2,
          vMaps: customVariables,
        })
      })(
        window,
        document,
        'script',
        `/api/v1/public/livesurveys/${code}/popup/script`,
        'zs_intercept'
      )
    },
    zsShowPopup() {
      const i = setInterval(function () {
        if (window.zsShowFrame !== undefined) {
          window.zsShowFrame()
          clearInterval(i)
        }
      }, 300)
    },
  },
}
