import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('score', [
      'messageSettings',
      'npsSettings',
      'csatSettings',
      'cesSettings',
    ]),
  },
  methods: {
    /**
     * Format data before submit or update nps settings to API
     * @returns {Object} Formatted data
     */
    formatNPSSettings() {
      return {
        id: this.npsSettings.id,
        is_active: this.npsSettings.is_active,
        main_question: this.npsSettings.main_question,
        scale_answer: {
          low: this.npsSettings.scale_answer_low,
          high: this.npsSettings.scale_answer_high,
        },
        follow_up_set: this.npsSettings.follow_up_set,
        follow_up_2: this.npsSettings.follow_up_2,
        follow_up_3: {
          follow_up_detractor: this.npsSettings.follow_up_detractor,
          follow_up_passive: this.npsSettings.follow_up_passive,
          follow_up_promoter: this.npsSettings.follow_up_promoter,
        },
        header: this.messageSettings.header,
        thanks_message: this.messageSettings.thanks_message,
        message_to_customer: this.messageSettings.customer_message,
      }
    },
    /**
     * Format data before submit or update csat settings to API
     * @returns {Object} Formatted data
     */
    formatCSATSettings() {
      return {
        id: this.csatSettings.id,
        is_active: this.csatSettings.is_active,
        main_question: this.csatSettings.main_question,
        answer_options: {
          rating_1: this.csatSettings.rating_1,
          rating_2: this.csatSettings.rating_2,
          rating_3: this.csatSettings.rating_3,
          rating_4: this.csatSettings.rating_4,
          rating_5: this.csatSettings.rating_5,
        },
        set_follow_up: this.csatSettings.set_follow_up,
        follow_up_2: this.csatSettings.follow_up_2,
        follow_up_3: {
          follow_up_rating_1: this.csatSettings.follow_up_rating_1,
          follow_up_rating_2: this.csatSettings.follow_up_rating_2,
          follow_up_rating_3: this.csatSettings.follow_up_rating_3,
          follow_up_rating_4: this.csatSettings.follow_up_rating_4,
          follow_up_rating_5: this.csatSettings.follow_up_rating_5,
        },
        header: this.messageSettings.header,
        thanks_message: this.messageSettings.thanks_message,
        message_callback_csat: this.messageSettings.customer_message,
      }
    },
    /**
     * Format data before submit or update csat settings to API
     * @returns {Object} Formatted data
     */
    formatCESSettings() {
      return {
        id: this.cesSettings.id,
        is_active: this.cesSettings.is_active,
        main_question: this.cesSettings.main_question,
        scale_answer: {
          bottom_scale: this.cesSettings.scale_answer_bottom,
          top_scale: this.cesSettings.scale_answer_top,
        },
        set_follow_up: this.cesSettings.set_follow_up,
        follow_up_2: this.cesSettings.follow_up_2,
        follow_up_3: {
          follow_up_bottom: this.cesSettings.follow_up_bottom,
          follow_up_neutral: this.cesSettings.follow_up_neutral,
          follow_up_top: this.cesSettings.follow_up_top,
        },
        header: this.messageSettings.header,
        thanks_message: this.messageSettings.thanks_message,
        message_callback_ces: this.messageSettings.customer_message,
      }
    },
  },
}
