/* eslint-disable no-console */
import mixpanel from 'mixpanel-browser'
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('template', ['credentials']),
    ...mapState('organization', ['companySize']),
    ...mapState('layouts', ['appConfig']),
    organization() {
      if (this.credentials.length)
        return {
          id: this.credentials[0].company_id ?? '000000',
          name: this.credentials[0].name ?? 'No company name provided',
        }
      else
        return {
          id: 'No company provided',
          name: 'No company name provided',
        }
    },
  },
  methods: {
    storeMixpanelMetric(name, additionalData, product = 'chat') {
      const prefix = `[${product.toUpperCase()}]`
      try {
        if (this.appConfig?.mixpanel?.status)
          mixpanel.track(`${prefix} ${name}`, additionalData)
      } catch (err) {
        console.error(err)
      }
    },
    getOrganizationForMetric() {
      return {
        'Company ID': this.organization.id,
        'Company Name': this.organization.name,
        'Company Size': this.companySize,
      }
    },
  },
}
