import moment from 'moment'
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('ticket', ['settings', 'isSettingsAvailable']),
    ...mapState('contact_object/contact', ['integrationList']),
  },
  methods: {
    loadingOn() {
      this.$store.commit('layouts/UPDATE_LOADINGBAR', true)
    },
    loadingOff() {
      this.$store.commit('layouts/UPDATE_LOADINGBAR', false)
    },
    setPageTitle(title) {
      this.$store.commit('layouts/PAGE_TITLE', title)
    },
    lower(text) {
      if (text) return text.toLowerCase()
      else return text
    },
    sliceWords(text, length) {
      if (text) {
        if (text.length > length) return text.substring(0, length) + '...'
        else return text
      } else {
        return text
      }
    },
    getFileIcon(type) {
      const master = 'la-file-'
      if (type) {
        if (type.includes('pdf')) return master + 'pdf'
        else if (type.includes('excel') || type.includes('spreadsheet'))
          return master + 'excel'
        else if (type.includes('word')) return master + 'word'
        else if (type.includes('ppt') || type.includes('presentation'))
          return master + 'powerpoint'
        else if (type.includes('excel')) return master + 'excel'
        else if (type.includes('csv')) return master + 'csv'
        else if (type.includes('zip')) return master + 'archive'
        else return master + 'alt'
      } else {
        return master + 'alt'
      }
    },
    getFilePixelIcon(type) {
      if (type) {
        if (type.includes('pdf')) return 'pdf-document'
        else if (type.includes('excel') || type.includes('spreadsheet'))
          return 'excel-document'
        else if (type.includes('word')) return 'word-document'
        else if (type.includes('ppt') || type.includes('presentation'))
          return 'doc'
        else if (type.includes('csv')) return 'doc'
        else if (type.includes('zip')) return 'zip'
        else return 'doc'
      } else if (!type) {
        return 'doc'
      }
    },
    isAgent() {
      if (!this.$auth.loggedIn) {
        return false
      } else if (this.$auth.user.role === 'agent') {
        return true
      } else {
        return false
      }
    },
    isSupervisor() {
      if (!this.$auth.loggedIn) {
        return false
      } else if (this.$auth.user.role === 'supervisor') {
        return true
      } else {
        return false
      }
    },
    isAdmin() {
      if (this.$auth.loggedIn && this.$auth.user.role === 'admin') {
        return true
      } else {
        return false
      }
    },
    isSuperAdmin() {
      if (this.$auth.loggedIn && this.$auth.user.role === 'super_admin') {
        return true
      } else {
        return false
      }
    },
    dateFormat(date) {
      return moment(date).format('DD-MM-YYYY, HH:mm:SS')
    },
    getCsatOptionIcon() {
      return [
        {
          inactive: require('assets/images/icons/icon-very-dissatisfied.svg'),
          active: require('assets/images/icons/very_dissatisfied.svg'),
          name: 'Very Dissatisfied',
        },
        {
          inactive: require('assets/images/icons/icon-dissatisfied.svg'),
          active: require('assets/images/icons/dissatisfied.svg'),
          name: 'Dissatisfied',
        },
        {
          inactive: require('assets/images/icons/icon-neutral.svg'),
          active: require('assets/images/icons/neutral.svg'),
          name: 'Neutral',
        },
        {
          inactive: require('assets/images/icons/icon-satisfied.svg'),
          active: require('assets/images/icons/satisfied.svg'),
          name: 'Satisfied',
        },
        {
          inactive: require('assets/images/icons/icon-very-satisfied.svg'),
          active: require('assets/images/icons/very_satisfied.svg'),
          name: 'Very Satisfied',
        },
      ]
    },
    ticketSettingAvailability() {
      return this.isSettingsAvailable && this.settings.is_active
    },
    getDataPercentage(count, sumOfAll, percentage = 100) {
      return Math.round((count / sumOfAll) * percentage * 10) / 10
    },
    getPasswordValidation() {
      return {
        required: true,
        regex: /^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[@#!?=_-]).*$/,
        min: 8,
      }
    },
    getPhoneValidation() {
      return {
        required: true,
        regex: /^[0-9+]*$/,
      }
    },
    findAllIndexes(string, word) {
      const result = []
      let dif = 0
      while (true) {
        const index = string.indexOf(word)
        if (index === -1) break
        else {
          result.push(index + dif)
          const cur = string.length
          string = string.substring(index + word.length)
          dif += cur - string.length
        }
      }
      return result
    },
    checkRoomUnresponded(unread, status) {
      if (unread < 1 && status === 'assigned') {
        return true
      } else {
        return false
      }
    },
    getObjectURL(file) {
      if (file) return URL.createObjectURL(file)
      else return ''
    },
    errorValidationMap(message, category, field) {
      if (message) return `You must ${category} ${field}`
      else return message
    },
    findServerChannelIntegrationId(channelId = null) {
      const channelName = this.integrationList.find(function (o) {
        return o.id === channelId
      })
      if (channelName) {
        switch (channelName.target_channel) {
          case 'wa':
            return channelName.settings.server_wa_id
          case 'whatsapp':
            return channelName.settings.server_wa_id
          case 'wa_cloud':
            return channelName.settings.phone_number
          case 'email':
            // return channelName.settings.username
            return 'Email'
          case 'telegram':
            return channelName.settings.bot_username
          case 'web_chat':
            return channelName.settings.domain
          case 'fb':
            // return channelName.settings.page_id
            return 'Facebook'
          case 'twitter':
            return '@' + channelName.settings.username
          case 'line':
            // return channelName.settings.bot_id
            return 'Line'
          case 'ig':
            return channelName.settings.page_name
          case 'ig_comment':
            return channelName.settings.instagram_id
          case 'livechat_dot_com':
            return channelName.settings.bot_name
          case 'app_chat':
            return channelName.settings.app_id
          default:
            return '-'
        }
      } else {
        return '-'
      }
    },
    findChannelName(channelId = null) {
      if (channelId) {
        const channelName = this.integrationList.find(function (o) {
          return o.id === channelId
        })
        if (channelName?.target_channel) {
          switch (channelName.target_channel) {
            case 'wa':
              return channelName.settings.account_name
            case 'whatsapp':
              return channelName.settings.account_name
            case 'wa_cloud':
              return channelName.settings.account_name
            case 'email':
              return channelName.settings.username
            case 'telegram':
              return channelName.settings.bot_name
            case 'web_chat':
              return channelName.settings.title
            case 'fb':
              return channelName.settings.page_name
            case 'twitter':
              return channelName.settings.name
            case 'line':
              return channelName.settings.bot_name
            case 'ig':
              return channelName.settings.instagram_name
            case 'ig_comment':
              return channelName.settings.instagram_name
            case 'livechat_dot_com':
              return channelName.settings.group_name
            case 'tokopedia_chat':
              return channelName.settings.store_name
            case 'shopee':
              return channelName.settings.shop_name
            case 'app_chat':
              return channelName.settings.app_name
            default:
              return 'not found'
          }
        } else {
          return 'not found'
        }
      } else {
        return 'not found'
      }
    },
    getChannelSettingsData(channelId = null) {
      const findChannel = this.integrationList.find(function (o) {
        return o.id === channelId
      })
      if (findChannel === undefined) {
        return 'not found'
      }
      return findChannel
    },
    numberFormat(number) {
      if (number) {
        return new Intl.NumberFormat(['ban', 'id']).format(number)
      } else {
        return '-'
      }
    },
    mapOrderStatusTokped(statusCode) {
      const data = {
        status: '',
        color: '',
      }
      switch (statusCode) {
        case 0:
          data.status = 'Seller cancel order'
          data.color = 'red'
          break
        case 3:
          data.status = 'Order Reject Due Empty Stock'
          data.color = 'red'
          break
        case 5:
          data.status = 'Order Canceled by Fraud'
          data.color = 'red'
          break
        case 6:
          data.status = 'Order Rejected (Auto Cancel Out of Stock)'
          data.color = 'red'
          break
        case 10:
          data.status = 'Order rejected by seller'
          data.color = 'red'
          break
        case 15:
          data.status = 'Instant Cancel by Buyer'
          data.color = 'red'
          break
        case 100:
          data.status = 'Order Created'
          data.color = 'blue'
          break
        case 103:
          data.status = 'Wait for payment confirmation from third party'
          data.color = 'blue'
          break
        case 220:
          data.status = 'Payment verified, order ready to process'
          data.color = 'blue'
          break
        case 221:
          data.status = 'Waiting for partner approval'
          data.color = 'orange'
          break
        case 400:
          data.status = 'Seller accept order'
          data.color = 'blue'
          break
        case 450:
          data.status = 'Waiting for pickup'
          data.color = 'blue'
          break
        case 500:
          data.status = 'Order shipment'
          data.color = 'blue'
          break
        case 501:
          data.status = 'Status changed to waiting resi have no input'
          data.color = 'orange'
          break
        case 520:
          data.status = 'Invalid shipment reference number (AWB)'
          data.color = 'red'
          break
        case 530:
          data.status =
            'Requested by user to correct invalid entry of shipment reference number'
          data.color = 'red'
          break
        case 540:
          data.status = 'Delivered to Pickup Point'
          data.color = 'blue'
          break
        case 550:
          data.status = 'Return to Seller'
          data.color = 'blue'
          break
        case 600:
          data.status = 'Order delivered'
          data.color = 'green'
          break
        case 601:
          data.status = 'Buyer open a case to finish an order'
          data.color = 'green'
          break
        case 690:
          data.status = 'Fraud Review'
          data.color = 'red'
          break
        case 700:
          data.status = 'Order finished'
          data.color = 'green'
          break
        default:
          data.status = '-'
          data.color = 'gray'
          break
      }
      return data
    },
    mapOrderStatusShopee(status) {
      const data = {
        status: '',
        color: '',
      }
      switch (status) {
        case 'UNPAID':
          data.status = 'Belum bayar'
          data.color = 'green'
          break
        case 'READY_TO_SHIP':
          data.status = 'Perlu dikirim'
          data.color = 'orange'
          break
        case 'PROCESSED':
          data.status = 'Perlu dikirim'
          data.color = 'orange'
          break
        case 'SHIPPED':
          data.status = 'Sedang dikirim'
          data.color = 'blue'
          break
        case 'COMPLETED':
          data.status = 'Selesai'
          data.color = 'green'
          break
        case 'IN_CANCEL':
          data.status = 'Pembatalan'
          data.color = 'orange'
          break
        case 'CANCELLED':
          data.status = 'Batal'
          data.color = 'gray'
          break
        case 'INVOICE_PENDING':
          data.status = 'Belum bayar'
          data.color = 'gray'
          break

        default:
          break
      }
      return data
    },
    generateRandomString() {
      const chars =
        '0123456789abcdefghijklmnopqrstuvwxyz!?=_-@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ'
      const stringLength = 15
      let stringText = ''
      for (let i = 0; i <= stringLength; i++) {
        const randomNumber = Math.floor(Math.random() * chars.length)
        stringText += chars.substring(randomNumber, randomNumber + 1)
      }
      return stringText + '789!'
    },
  },
}
